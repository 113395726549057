<template>
  <div class="sale-wrapper bj">
    <Filtersearch
      ref="Filtersearchref"
      :list="Filtersearchlist"
      :fromdata="formData"
      @Refresh="Refresh"
      @search="search"
    >
      <template v-slot:right>
        <el-button
          type="warning"
          icon="el-icon-upload2"
          @click="onHandleExport"
          :disabled="!tableData.length || disabledExport"
          >导出
        </el-button>
      </template>
    </Filtersearch>
    <div class="auto-table-flex">
      <table-list v-loading="loading" :tableData="tableData"></table-list>
    </div>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import TableList from "./modules/table-list/detail";
import {
  getSupplierSelectList,
  postBuyerSelectList,
  postSettlementSupplierServiceChargesOrderList,
} from "@/api/general/operation-center/index.js";
import { postSettlementSupplierServiceChargesOrderListExport } from "@/api/export/center.js";
import Filtersearch from "@/components/Filtersearch.vue";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "warehouseGoods",
  components: { TableList, Filtersearch },
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      supplier_at: [],
      settlement_at: [],
      loadingSupplierList: false,
      loadingBuyerList: false,
      supplierList: [],
      buyerList: [],
      Filtersearchlist: [
        {
          type: "select",
          name: "集配中心",
          clearable: true,
          model: "logistics_business_id",
          placeholder: "请选择集配中心",
          selectoptionname: "business_list",
          label: "name",
          value: "id",
          selectoption: [],
        },
        {
          type: "select",
          name: "供应商",
          clearable: true,
          filterable: true,
          model: "supplier_id",
          placeholder: "请选择供应商",
          selectoptionname: "supplierList",
          label: "title",
          value: "id",
          selectoption: [],
        },
        {
          type: "select",
          name: "采购",
          clearable: true,
          filterable: true,
          model: "buyer_id",
          placeholder: "请选择采购",
          selectoptionname: "buyerList",
          label: "fullname",
          value: "id",
          selectoption: [],
        },
        {
          type: "input",
          name: "供货单号",
          clearable: true,
          model: "supplier_order_no",
          placeholder: "请输入供货单号",
        },
        {
          type: "daterange",
          name: "供货日期",
          clearable: true,
          model: "supplier_at",
          startmodel: "supply_time_start",
          endmodel: "supply_time_end",
          startplaceholder: "供货日期起",
          endplaceholder: "供货日期止",
        },
        {
          type: "daterange",
          name: "结算日期",
          clearable: true,
          model: "settlement_at",
          startmodel: "settlement_time_start",
          endmodel: "settlement_time_end",
          startplaceholder: "结算日期起",
          endplaceholder: "结算日期止",
        },
        {
          type: "input",
          name: "订单号",
          clearable: true,
          model: "order_no",
          placeholder: "请输入订单号",
        },
        {
          type: "input",
          name: "商品名称",
          clearable: true,
          model: "product_title",
          placeholder: "请输入商品名称",
        },
        {
          type: "input",
          name: "商品货号",
          clearable: true,
          model: "sku_code",
          placeholder: "请输入商品货号",
        },
      ],
      formData: {
        logistics_business_id: "",
        supplier_id: "",
        buyer_id: "",
        supplier_order_no: "",
        supply_time_start: "",
        supply_time_end: "",
        supplier_at: [],
        settlement_at: [],
        settlement_time_start: "",
        settlement_time_end: "",
        page: 1,
        pageSize: 50,
        order_no: "",
        product_title: "",
        sku_code: "",
      },
      formDataClone: {},
      tableData: [],
      options: [],
      currentPage: 1,
      total: 0,
      disabledExport: false, // 导出按钮
    };
  },
  created() {
    console.log(
      this.formData.sku_code,
      this.formData.sku_code,
      this.$route.query,
      "222"
    );
    this.formDataClone = cloneDeep(this.formData);
    this.formData.sku_code = this.$route.query.sku_code
      ? this.$route.query.sku_code
      : "";
    this.formData.supplier_order_no = this.$route.query.supplier_order_no
      ? this.$route.query.supplier_order_no
      : "";
    this.getBusinessList();
    this.ajaxGetSupplierSelectList();
    this.postAjaxBuyerSelectList();
    this.hqlist();
  },
  watch: {},
  methods: {
    /**
     * 时间选择
     */
    // timeChange(val, type) {
    //   console.log(val, type);
    //   if (type == 1) {
    //     if (val) {
    //       this.from1.supply_time_start = val[0];
    //       this.from1.supply_time_end = val[1];
    //     } else {
    //       this.from1.supply_time_start = "";
    //       this.from1.supply_time_end = "";
    //     }
    //   } else {
    //     if (val) {
    //       this.from1.settlement_time_start = val[0];
    //       this.from1.settlement_time_end = val[1];
    //     } else {
    //       this.from1.settlement_time_start = "";
    //       this.from1.settlement_time_end = "";
    //     }
    //   }
    // },
    /**
     * 获取供应商下拉列
     */
    async ajaxGetSupplierSelectList() {
      // this.loadingSupplierList = true;
      try {
        const { data } = await getSupplierSelectList();
        this.supplierList = data;
        this.Filtersearchlist.forEach((el) => {
          if (
            el.type == "select" &&
            el.selectoptionname &&
            el.selectoptionname == "supplierList"
          ) {
            el.selectoption = [{ id: "", title: "全部" }, ...data];
          }
        });
      } catch (err) {
        console.log("ajax getSupplierSelectList err", err);
      } finally {
        // this.loadingSupplierList = false;
      }
    },
    /**
     * 获取业务下拉列
     */
    async postAjaxBuyerSelectList() {
      // this.loadingBuyerList = true;
      try {
        const { data } = await postBuyerSelectList();
        this.buyerList = data;
        this.Filtersearchlist.forEach((el) => {
          if (
            el.type == "select" &&
            el.selectoptionname &&
            el.selectoptionname == "buyerList"
          ) {
            el.selectoption = [{ id: "", fullname: "全部" }, ...data];
          }
        });
      } catch (err) {
        console.log("ajax postBuyerSelectList err", err);
      } finally {
        // this.loadingBuyerList = false;
      }
    },
    /**
     * 导出
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        const query = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
        };
        delete query.page;
        delete query.pageSize;
        await postSettlementSupplierServiceChargesOrderListExport(query);
        this.goExportCenter();
      } catch (err) {
        this.disabledExport = false;
        console.log(
          "ajax postSettlementSupplierServiceChargesOrderListExport err",
          err
        );
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    // 获取集配中心
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
        this.Filtersearchlist.forEach((el) => {
          if (
            el.type == "select" &&
            el.selectoptionname &&
            el.selectoptionname == "business_list"
          ) {
            el.selectoption = [{ id: "", name: "全部" }, ...res.data];
          }
        });
      });
    },
    // 搜索查询
    search(e) {
      this.formData = e;
      if (this.formData.sku_code && this.formData.sku_code.length < 4) {
        this.$message.error("货号最少输入4位");
        return;
      }
      this.currentPage = 1;
      this.hqlist();
    },
    // 重置列表
    Refresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.formData.page = val;
      this.currentPage = val;
      this.hqlist();
    },
    async hqlist() {
      this.loading = true;
      try {
        const res = await postSettlementSupplierServiceChargesOrderList(
          this.formData
        );
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.loading = false;
      } catch (error) {
        console.log(error, "postSettlementSupplierServiceChargesOrderList");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sale-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .goods-image {
    width: 50px;
    height: 50px;
  }

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    // display: flex;

    .seach {
      // flex: 1;
      display: flex;
      flex-wrap: wrap;
      // justify-content: flex-end;

      .inputs {
        // width: 207px;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
      margin-bottom: 8px;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
